import React, { useEffect } from "react";
import styled from "styled-components";

const SVideo = styled.video``;

export const UtVideo = ({ src, subtitle, ...props }) => {
  useEffect(() => {}, [src]);

  let mySrc = src,
    mySub = subtitle;
  try {
    let _ = new URL(src);
  } catch {
    mySrc = `${process.env.REACT_APP_BACKEND_URL}${src}`;
  }
  try {
    let _ = new URL(subtitle);
  } catch {
    mySub = `${process.env.REACT_APP_BACKEND_URL}${subtitle}`;
  }

  return (
    <SVideo {...props} key={mySrc} autoPlay={false} crossOrigin="anonymous">
      <source src={mySrc} />
      {subtitle && (
        <track
          label="English"
          kind="subtitles"
          srcLang="en"
          src={mySub}
          default
        />
      )}
      Sorry, your browser doesn't support embedded videos.
    </SVideo>
  );
};

UtVideo.propTypes = {};
