import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";

function Poc() {
  const [name, setName] = React.useState("");
  return (
    <div>
      {name.length > 3 && (
        <PDFDownloadLink
          document={
            <Document>
              <Page size="A5" orientation="landscape">
                <View wrap={false}>
                  <Text
                    style={{
                      position: "absolute",
                      top: 180,
                      width: "100%",
                      textAlign: "center",
                      right: 50,

                      fontSize: 25,
                      color: "red",
                    }}
                  >
                    {name}
                  </Text>
                  <Image
                    src={
                      "https://res.cloudinary.com/dpmw1bvdz/image/upload/v1703922656/BG_dpcqzb.png"
                    }
                    style={{
                      zIndex: -1,
                    }}
                  />
                </View>
              </Page>
            </Document>
          }
          fileName="utpal.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download now!"
          }
        </PDFDownloadLink>
      )}
    </div>
  );
}

export const LearnerCertificate = ({ name }) => {
  return (
    <PDFViewer width="100%" height="700px">
      <Document title={`${name}-certificate.pdf`} author="UnTaboo">
        <Page size="A5" orientation="landscape">
          <View wrap={false}>
            <Text
              style={{
                position: "absolute",
                top: 205,
                width: "100%",
                textAlign: "center",
                fontSize: 25,
                color: "black",
                transform: "translateX(-50%)",
              }}
            >
              {name}
            </Text>
            <Image
              src={`${process.env.REACT_APP_BACKEND_URL}uploads/certificate/assets/b2c_certificate.png`}
              style={{
                zIndex: -1,
              }}
            />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export const ParentCertificate = ({ name, isFather }) => {
  let certificate =
    "https://ik.imagekit.io/bizwem110d/untaboo/momcert_t-pdhURH1.png";
  if (isFather)
    certificate =
      "https://ik.imagekit.io/bizwem110d/untaboo/dadcert_-5oaMLHmY.png";
  return (
    <PDFViewer width="100%" height="700px">
      <Document title={`${name}-parent-certificate.pdf`} author="UnTaboo">
        <Page size="A5" orientation="landscape">
          <View wrap={false}>
            <Text
              style={{
                position: "absolute",
                top: 160,
                width: "100%",
                textAlign: "center",
                fontSize: 25,
                color: "black",
              }}
            >
              {name}
            </Text>
            <Image
              src={certificate}
              style={{
                zIndex: -1,
              }}
            />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export const LearnerSchoolCertificate = ({
  name,
  post_percent,
  grade,
  gender,
}) => {
  let badge_image = "Happy Explorer.png";
  if (post_percent > 20 && post_percent <= 40) {
    badge_image = "Smart Explorer.png";
  } else if (post_percent > 40 && post_percent <= 60) {
    badge_image = "Amazing Explorer.png";
  } else if (post_percent > 60 && post_percent <= 80) {
    badge_image = "Expert Explorer.png";
  } else if (post_percent > 80) {
    badge_image = "Champion Explorer.png";
  }
  let gender_image = gender === "M" ? "Boys" : "Girls";
  let grade_image = `Grade${grade}`;
  return (
    <PDFViewer width="100%" height="700px">
      <Document title={`${name}-certificate.pdf`} author="UnTaboo">
        <Page size="A5" orientation="landscape">
          <View wrap={false}>
            <Image
              src={`${process.env.REACT_APP_BACKEND_URL}uploads/certificate/assets/Badges/${badge_image}`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: 120,
                width: 120,
              }}
            />
            <Text
              style={{
                position: "absolute",
                top: 180,
                width: "100%",
                textAlign: "center",
                fontSize: 25,
                color: "black",
              }}
            >
              {name}
            </Text>
            <Image
              src={`${process.env.REACT_APP_BACKEND_URL}uploads/certificate/assets/${grade_image}/${grade_image}${gender_image}/Background/BG.png`}
              style={{
                zIndex: -1,
              }}
            />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export const SchoolCertificate = ({ name }) => {
  return (
    <PDFDownloadLink
      document={
        <Document>
          <Page size="A5" orientation="landscape">
            <View wrap={false}>
              <Text
                style={{
                  position: "absolute",
                  top: 150,
                  width: "100%",
                  textAlign: "center",
                  fontSize: 25,
                  color: "black",
                }}
              >
                {name}
              </Text>
              <Image
                src={`${process.env.REACT_APP_BACKEND_URL}uploads/certificate/assets/SchoolFeedback/SchoolCertificate.png`}
                style={{
                  zIndex: -1,
                }}
              />
            </View>
          </Page>
        </Document>
      }
      fileName={`${name}-certificate.pdf`}
      className="text-decoration-none text-white"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now"
      }
    </PDFDownloadLink>
  );
};
